import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
import {thunk} from 'redux-thunk';
import { rootReducers } from "./RootReducer";
import {persistStore} from 'redux-persist'

const middleware = [thunk];

export const store = configureStore({
  reducer : rootReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  devTools: true,
})

export const persistor = persistStore(store)