import React from 'react'
import Footer from "../Footer/Footer"
import "./IglDiamondEducationStyles.css" 

const IglDiamondEducation = () => {
  return (
    <>
      <div className="diamond-education-container fluid-container">
        <div className="row">
          <div className="col-12 diam-edu-title">DIAMOND EDUCATION</div>
          <div className="col-12 diam-edu-description">
            DIAMOND EDUCATION IS THE UNDERSTANDING OF THE 4 C’S OF DIAMONDS
            (COLOR, CUT, CARAT, CLARITY) AND THE FACTORS THAT IMPACT DIAMOND
            QUALITY AND PRICE.
          </div>
          <div className="diam_edu_button_container">
            <button className="diam_edu_button">Learn More</button>
          </div>
        </div>
      </div>
      <div className="get-started container">
        <div className="edu-get-started-title col-12">Let’s Get Started</div>
        <div className="edu-get-started-description col-12">
          Every polished diamond is valuable. However, a diamond’s
          characteristics determine how high we value it compared to other
          diamonds. Some qualities are more rare than others, and jewelry
          professionals use systematic grading scales to evaluate each stone.
          Grading systems developed by gemological laboratories such as the
          Gemological Institute of America (GIA) and American Gem Society (AGS)
          set the standard. These and a few other laboratories represent the
          most trusted certifying bodies in the diamond industry.
        </div>
      </div>
      <div className="diam-education-information container">
        <div className="information_card row justify-content-between">
          <div className="edu-card-outline col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="edu-information_card_title">Diamond Shape</div>
            <p className="edu-information_card_description">
              A diamond’s shape is one of its most defining characteristics.
            </p>
          </div>
          <div className="edu-card-outline col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="edu-information_card_title">Diamond Color</div>
            <p className="edu-information_card_description">
              Diamond color is the hue of a stone based on the color scale.
            </p>
          </div>
          <div className="edu-card-outline col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="edu-information_card_title">Fluorescence</div>
            <p className="edu-information_card_description">
              Fluorescence is an important factor when buying a diamond.
            </p>
          </div>
          <div className="edu-card-outline col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="edu-information_card_title">Diamond Carat</div>
            <p className="edu-information_card_description">
              The diamond carat is the unit of measurement of a diamond’s
              weight.
            </p>
          </div>
          <div className="edu-card-outline col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="edu-information_card_title">Diamond Cut</div>
            <p className="edu-information_card_description">
              A diamond cut is a style or design guide used when shaping a
              diamond for polishing.
            </p>
          </div>
          <div className="edu-card-outline col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="edu-information_card_title">Diamond Clarity</div>
            <p className="edu-information_card_description">
              Diamond clarity indicates the presence or absence of inclusions
              and blemishes.
            </p>
          </div>
          <div className="edu-card-outline col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="edu-information_card_title">Diamond Culet</div>
            <p className="edu-information_card_description">
              The culet is the tiny facet or point at the bottom of a diamond,
              where the pavilion facets meet.
            </p>
          </div>
          <div className="edu-card-outline col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="edu-information_card_title">Diamond Girdle</div>
            <p className="edu-information_card_description">
              The girdle is the thin perimeter of a diamond, dividing the crown
              from the pavilion.
            </p>
          </div>
          <div className="edu-card-outline col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="edu-information_card_title">Diamond Grading</div>
            <p className="edu-information_card_description">
              A Diamond Grading Report includes all of a stone’s defining
              characteristics and scores.
            </p>
          </div>
          <div className="edu-card-outline col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="edu-information_card_title">Diamond Polish</div>
            <p className="edu-information_card_description">
              Polish and symmetry are key elements that impact a diamond’s
              finish and sparkle.
            </p>
          </div>
          <div className="edu-card-outline col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="edu-information_card_title">Diamond Depth</div>
            <p className="edu-information_card_description">
              Learn how diamond depth and table size can affect its cut and
              brilliance.
            </p>
          </div>
          <div className="edu-card-outline col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="edu-information_card_title">Diamond Shape</div>
            <p className="edu-information_card_description">
              Discover the ideal crown angle and pavilion depth for the perfect
              diamond.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default IglDiamondEducation
