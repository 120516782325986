/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const InputText = ({
  onChange,
  onBlur,
  placeholder,
  label = "",
  input,
  iconFontClass,
  type = 'text',
  disabled = false,
  className,
  error,
  touched,
  required = true,
  addonBefore = null,
  textIndentRequire = false,
  onKeyDown
}) => {
  return (
    <>
    <div className='input-text-container'>
      <label>
        {label} {required && <span className='text-danger'>*</span>}
      </label>
      {addonBefore && (
          <span className="input-group-addon">
            <span className="input-group-addon-text">{addonBefore}</span>
          </span>
        )}
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        className={
          touched && error && error[input.name] && !disabled
            ? 'form-control border-danger'
            : 'form-control'
        }
        disabled={disabled}
        autoComplete='off'
        onChange={(event) => {
          onChange(event.target.value)
        }}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        style={{textIndent: textIndentRequire ? "30px" : "5px"}}
      />
      {touched && error && error[input.name] && !disabled && (
        <div className='fv-plugins-message-container text-danger'>
          <div className='fv-help-block'>{error[input.name]}</div>
        </div>
      )}
      </div>
    </>
  )
}

export {InputText}