// auth.js
import { SET_CERTIFICATE_DATA} from "../constants/actionTypes";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  certificateData: null,
};

export const actions = {
  setCertificateData: (certificateData) => ({type: SET_CERTIFICATE_DATA, payload: certificateData}),
}

const certificateReducer = persistReducer(
  { storage, key: 'certificate', whitelist: ['certificateData'] },
  (state = initialState, action) => {
    switch (action.type) {
      case SET_CERTIFICATE_DATA:
        return { ...state, certificateData: action.payload };
      default:
        return { ...state };
    }
  }
);

export default certificateReducer;
