import React from "react";
import "./IglAboutUsStyles.css";
import Globe from "../../assets/globe.png";
import Auditor from "../../assets/auditor.png";
import Power from "../../assets/power.png";
import Footer  from "../Footer/Footer";
import AboutUsDiam from "../../assets/about_us.svg"

const IglAboutUs = () => {
  return (
    <>
    <div className="igl-about-us-container">
      <img className="about_img"  src={AboutUsDiam} alt="Background"  />
      <div className="igl-about-info container">
        <div className="row">
          <div className="col-12 about-title">About Us</div>
          <div className="col-10 about-sub-title">
            OUR MISSION IS TO ENSURE CUSTOMER TRUST BY UPLOADING INTERNATIONAL
            STANDARD REPORT
          </div>
          <div className="col-12 about-description">
            India’s most respected retailers, wholesalers, auction houses, and
            private collectors rely on the expertise of IGL graders to assess,
            grade & verify their gems. They recognize the importance of
            complete, unbiased, scientific information in gem assessment, and
            absolutely trust IGL to provide it. International Gemological
            Laboratory India has experienced an international graduate team who
            strictly follows the international grading standard for your
            certification report. We are top leading jewelry certification
            laboratory in India. We started in 2012 for jewelry certification in
            New Delhi and now we are available all over India.
          </div>
        </div>
      </div>
      <div className="team-container container">
        <div className="row">
          <div className="col-12 team-title">
            “The Right Team. The Right Solution.”
          </div>
          <div className="col-12 team-description">
            In the modern world, even experts require specialized equipment to
            detect gemstone treatments and determine natural or lab-grown
            origins.
          </div>
        </div>
      </div>

      <div className="about_service_container container">
        <div className="row gx-5 justify-content-between">
          <div className="service_card_stroke col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="about_service_title">
              Diamond Jewelry Certification
            </div>
            <p className="about_service_description">
              Trusted by thousands of top Indian Brand for jewelry certification
            </p>
          </div>
          <div className="service_card_stroke col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="about_service_title">Diamond Grading Report</div>
            <p className="about_service_description">
              We follow international grading method for diamond grading all
              over india
            </p>
          </div>
          <div className="service_card_stroke col-lg-3 col-md-5 col-sm-12 col-12">
            <div className="about_service_title">
              Laboratory Grown Diamond Report
            </div>
            <p className="about_service_description">
              Its also referred to as “man-made”, “created” or “synthetic”
              diamonds
            </p>
          </div>
        </div>
      </div>

      <div className="counter-container fluid-container">
        <div className="show_count_container row justify-content-between">
          <div className="col-lg-3.5 col-md-4 col-sm-12 col-12 mb-2">
            <div className="count_number">300,000</div>
            <p className="count_title">JEWELRY CERTIFIED</p>
          </div>
          <div className="col-lg-3.5 col-md-4 col-sm-12 col-12 mb-2">
            <div className="count_number">9,540</div>
            <p className="count_title">CONNECTED BRANDS</p>
          </div>
          <div className="col-lg-3.5 col-md-4 col-sm-12 col-12 mb-2">
            <div className="count_number">54,800</div>
            <p className="count_title">HAPPY CUSTOMERS</p>
          </div>
        </div>
      </div>

      <div className="brief-service-container container">
        <div className="row">
          <div className="col-12 brief-title">
            We Thrive Ourselves On Creativity, Intuition & Determination
          </div>
          <div className="col-12 brief-description">
            In the modern world, even experts require specialized equipment to
            detect gemstone treatments and determine natural or lab-grown
            origins.
          </div>
        </div>
      </div>

      <div className="brief-detail-card-container container">
        <div className="row justify-content-between">
          <div className="brief-detail-card col-lg-4 col-md-4 col-sm-12">
            <div className="image-circle">
              <img className="inner-image" src={Auditor} />
            </div>
            <div className="brief-card-border-styling">
            <div className="brief-detail-card-title">We Are Auditors</div>
            <div className="brief-detail-card_description">
              IGL – India has a team of experts in gemological testing with
              International grading system.
            </div>
            </div>
          </div>
          <div className="brief-detail-card col-lg-4 col-md-4 col-sm-12">
            <div className="image-circle">
              <img className="inner-image" src={Globe} />
            </div>
            <div className="brief-card-border-styling">
            <div className="brief-detail-card-title">We Have Intuition</div>
            <div className="brief-detail-card_description">
              IGL-India has online & offline institutions for gemological with
              World’s best graduates in Gemology
            </div>
            </div>
          </div>
          <div className="brief-detail-card col-lg-4 col-md-4 col-sm-12">
            <div className="image-circle">
              <img className="inner-image" src={Power} />
            </div>
            <div className="brief-detail-card-title">We Are Determined</div>
            <div className="brief-detail-card_description">
              We determined the right quality certification with the
              international standard of Gemology.
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default IglAboutUs;
