/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import IglDiamondEducation from './../../components/DiamondEducation/IglDiamondEducation';

const DiamondEducationWrapper = () => {
  return (
    <>
      <IglDiamondEducation />
    </>
  )
}

export {DiamondEducationWrapper}
