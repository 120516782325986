import React, {useEffect, useState} from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import "./ReportSummaryFormStyles.css";
import "./pdfStyles.css";
import { Button } from "antd";
import PDF_FOOTER_IMG from "../../assets/pdf-footer.jpeg"

const ReportSummaryFormForPdf = ({ exportToPDF }) => {
  const certiData = useSelector((state) => state.certificate.certificateData);
  const [base64Image, setBase64Image] = useState("");

  async function convertImageToBase64(imageUrl) {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = () => reject(new Error("Failed to convert image to Base64"));
      reader.readAsDataURL(blob);
    });
  }

  useEffect(() => {
    if (certiData?.image_url) {
      convertImageToBase64(certiData.image_url).then((base64) => {
        setBase64Image(base64);
      }).catch(error => {
        console.error("Error converting image to Base64:", error);
      });
    }
  }, [certiData]);

  return (
    <>
      {certiData ? (
        <div className="pdf-main-container">
          <div
            className="pdf-container fluid-container"
            style={{ position: "relative" }}
          >
            <div className="pdf-header">
              <div className="pdf-pattern">
                <div className="pdf-title-container">
                  <div className="pdf-logo" />
                  <div className="pdf-title">IGL</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            {/* <div className="report-summary-container fluid-container"> */}
            <div
              className="col-12 report-summary-header-title"
              style={{ marginTop: "30px" }}
            >
              Diamond Report Summary
            </div>
          </div>
          {/* </div> */}
          <div className="report-form-container fluid-container">
            <div className="row">
                <div className="row form-row">
                  {certiData.vendor_id && (
                    <div className="col-lg-2 col-12 title-field-container" style={{marginBottom:"35px"}}>
                      <span className="form-title" style={{fontWeight:"700"}}>
                        {certiData.vendor_id.first_name &&
                        certiData.vendor_id.last_name
                          ? `${certiData.vendor_id.first_name} ${certiData.vendor_id.last_name}`
                          : certiData.vendor_id.first_name
                          ? certiData.vendor_id.first_name
                          : "-"}
                      </span>
                    </div>
                  )}
                  <div className="col-lg-7 col-12 align-value-field">
                    <span
                      className="form-value"
                      style={{ color: "rgba(0,0,0,0.5)" }}
                    >
                      {certiData.createdAt
                        ? new Date(certiData.createdAt).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "-"}
                    </span>
                    {/* <span className="form-value">09IGL2212337</span> */}
                  </div>
                </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-7 col-sm-12 col-12">
                <div className="row form-row">
                  <div className="col-lg-4 col-12 title-field-container">
                    <span className="form-title">Summary Number</span>
                  </div>
                  <div className="col-lg-7 col-12 form-value-background align-value-field">
                    <span className="form-value">
                      {certiData.certificate_number
                        ? certiData.certificate_number
                        : "-"}
                    </span>
                    {/* <span className="form-value">09IGL2212337</span> */}
                  </div>
                </div>
                <div className="row form-row">
                  <div className="col-lg-4 col-12 title-field-container">
                    <span className="form-title">Description</span>
                  </div>
                  <div
                    className="col-lg-7 col-12 form-value-background align-value-field"
                    style={{ maxHeight: "140px" }}
                  >
                    <span className="form-value">
                      {certiData.description ? certiData.description : "-"}
                    </span>
                    {/* <span className="form-value">
                    One 14K Gold EARRING , Wieght in total 3.24 grms in natural
                    diamonds
                  </span> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-12 col-12">
                <div
                  className="report-image-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="report-summary-image">
                    <img
                      src={base64Image}
                      alt="diamond Image"
                      style={{
                        width: "100%",
                        height: "100%",
                        fontSize: "20px",
                        borderRadius: "16px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="row form-row">
                  <div className="col-6 title-field-container">
                    <span className="form-title">Shape/Cut</span>
                  </div>
                  <div className="col-6 form-value-background">
                    <span className="form-value">
                      {certiData.shape_cut ? certiData.shape_cut : "-"}
                    </span>
                    {/* <span className="form-value form-value-background">
                    Round Brilliant
                  </span> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="row form-row">
                  <div className="col-6 title-field-container">
                    <span className="form-title">Total Est. Wt.</span>
                  </div>
                  <div className="col-6  form-value-background">
                    {/* <span className="form-value form-value-background">0.75</span> */}
                    <span className="form-value">
                      {certiData.gross_weight ? certiData.gross_weight : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="row form-row">
                  <div className="col-6 title-field-container">
                    <span className="form-title">Colour</span>
                  </div>
                  <div className="col-6 form-value-background">
                    {/* <span className="form-value">I-J</span> */}
                    <span className="form-value">
                      {certiData.color ? certiData.color : "-"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="row form-row">
                  <div className="col-6 title-field-container">
                    <span className="form-title">Clarity</span>
                  </div>
                  <div className="col-6 form-value-background">
                    <span className="form-value">
                      {certiData.clarity ? certiData.clarity : "-"}
                    </span>
                    {/* <span className="form-value">SI</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="row form-row">
                  <div className="col-6 title-field-container">
                    <span className="form-title">Diamond Weight</span>
                  </div>
                  <div className="col-6  form-value-background">
                    {/* <span className="form-value form-value-background">0.75</span> */}
                    <span className="form-value">
                      {certiData.diamond_weight ? certiData.diamond_weight : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-md-3 col-sm-6 col-12 title-field-container">
                <span className="form-title">Comments</span>
              </div>
              <div className="col-md-9 col-sm-6 col-12 form-value-background">
                {/* <span className="form-value">
                Grading & Identification of origin as mounting permits. Summary
                number engraved. Style #
              </span> */}
                <span className="form-value">
                  {certiData.comments ? certiData.comments : "-"}
                </span>
              </div>
            </div>
            <div
              className="image-view-button"
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
            </div>
            {/* <div className="row form-row">
          <div className="col-sm-3 col-6 title-field-container">
            <span className="form-title">Add Image</span>
          </div>
          <div className="col-sm-9 col-6">
            <span className="form-value">
              <PlusOutlined
                style={{
                  fontSize: "100px",
                  background: "#F3F3F3",
                  padding: "10px",
                }}
              />
            </span>
          </div>
        </div> */}
          </div>
          <div className="pdf-footer">
            <img src={PDF_FOOTER_IMG} alt="diamond" className="pdf-footer-img" />
          </div>
        </div>
      ) : (
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgba(255, 41, 25,1)",
            fontSize: "60px",
            fontWeight: "600",
          }}
        >
          <div>NO DETAILS FOUND</div>
        </div>
      )}
    </>
  );
};

export default ReportSummaryFormForPdf;
