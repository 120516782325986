import React from "react";
import { InlineWidget } from "react-calendly";

const ContactUsCalender = () => {
  return (
    <div className="ContactUsCalender">
      <InlineWidget url="https://calendly.com/iglaboratoryindia/30min" />
    </div>
  );
};

export default ContactUsCalender;