import React, { useState } from "react";
import "./CheckReportStyles.css";
import { InputText } from "../../Common/FormComponents/InputText";
import Footer from "../Footer/Footer";
import { Button, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as certificate from "../../redux/certificate";
import { getCertificateReportData } from "../../graphs/CheckReport/CertificateReportDataApi";

const CheckReport = () => {
  const [reportNumber, setReportNumber] = useState("");
  const [loader, setLoader] = useState(false);
  const [isValidReportNumber, setIsValidReportNumber] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const callCertificateData = async () => {
    try {
      let certiData = await getCertificateReportData(reportNumber);
      console.log(certiData, "data!!!");
      if (certiData && certiData.data && certiData.data.data) {
        await dispatch(
          certificate.actions.setCertificateData(certiData.data.data)
        );
      }
    } catch (e) {
      console.log("!!!!Error", e);
    }
  };

  const navigateToVerifyCertificate = async () => {
    try {
      setLoader(true);
      // await dispatch(
      //   certificate.actions.setCertificateData(null));
      // await callCertificateData();
      await navigate(`/report/${reportNumber}`);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  const handleReportNumberChange = (value) => {
    setReportNumber(value);

    const isValid = /^[a-zA-Z0-9]+$/.test(value);
    setIsValidReportNumber(isValid);
  };

  const onKeyDown = (e) => {
    console.log(e.key,isValidReportNumber)
    if (e.key === "Enter" && isValidReportNumber) {
      navigateToVerifyCertificate();
    }
  };

  return (
    <>
      <Spin size="large" spinning={loader} tip="Generating Report...">
        <div className="check-report-container container">
          <div className="fluid-container">
            <div className="row">
              <div className="col-12 check-report-title-container">
                <span className="report-check-title">Verify Certificate</span>
              </div>
              <div className="col-12 check-report-desc-container">
                <div className="report-check-desc">
                  With Report Check, you can efficiently and conveniently verify
                  that the information on your report aligns with the data
                  stored in the IGL report database. This tool ensures accuracy
                  and reliability, allowing you to have complete confidence in
                  the integrity of your report.
                </div>
              </div>
            </div>

            <div className="row report-number-input-container">
              <div className="report-number-input-field col-lg-9 col-md-8 col-sm-8 col-12">
                <InputText
                  input={{
                    value: reportNumber,
                    id: "report_number_input_field",
                    name: "report_number",
                  }}
                  placeholder="Enter Report Number"
                  onChange={handleReportNumberChange}
                  onBlur={() => {}}
                  error={() => {}}
                  touched={() => {}}
                  onKeyDown={onKeyDown} 
                />
                {!isValidReportNumber && (
                  <div
                    className="text-danger"
                    style={{ fontSize: "16px", marginLeft: "20px" }}
                  >
                    Please Enter Report Number
                  </div>
                )}
              </div>
              <div className="check_report_button_container col-lg-3 col-md-4 col-sm-4 col-12">
                <button
                  className="check_report_button"
                  disabled={!isValidReportNumber}
                  onClick={() => navigateToVerifyCertificate()}
                  style={{
                    cursor: !isValidReportNumber ? "not-allowed" : "pointer",
                    zIndex: "2",
                  }}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
      <Footer />
    </>
  );
};

export default CheckReport;
