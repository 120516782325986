import React, { FC } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import PublicRoutes  from "./PublicRoutes";
import AppLayoutHOC from "../Common/AppLayout/AppLayoutHOC";

const AppLayout = AppLayoutHOC(PublicRoutes);

const Router = () => {

  return (
    <Routes>
        <Route path="/*" element={<AppLayout />} />
    </Routes>
  );
};

export { Router };
