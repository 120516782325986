import React from "react";
import "./IglMainPageStyles.css";
import Diamond from "../../assets/igl_main_page_diamond.svg";
import SMALL_DIAMONDS from "../../assets/small_diamonds.svg";
import Diam_border from "../../assets/main_page_diam_with_border.svg";
import MOON from "../../assets/moon_eclipse.svg";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

const IglMainPage = () => {
  const navigate = useNavigate();

  const navigateToReport = () => {
    navigate("/check-report");
  };

  return (
    <div className="main-page-container" style={{ overflowX: "clip" }}>
      <div className="fluid-container">
        <div className="welcome-main-page row">
          <div className="col-8">
            <div id="text-over-container">
              <div id="main_title">Welcome to IGL India</div>
              <div id="sub_title">Diamond Jewelry Grading Laboratory</div>
              <button
                className="main_header_button"
                onClick={() => navigateToReport()}
              >
                Verify Your Report
              </button>
            </div>
          </div>
          <div className="col-4 border_parent">
            <img
              className="diamond_border_svg"
              src={Diam_border}
              alt="Diamond"
            />
            <div className="svg_background">
              <img className="diamond_svg" src={Diamond} alt="Diamond" />
            </div>
          </div>
        </div>
      </div>
      <div className="feature-card row gap-0.5">
        <div className="detail-card col-lg-6 col-md-6 col-sm-12">
          <div className="outer-circle">
            <span className="inner-number">1</span>
          </div>
          <div id="card_title">Diamond Report</div>
          <div id="card_description">
            IGL India Diamond Reports accurately assesses a diamond’s cut,
            color, clarity, and carat weight strictly following international
            standards.
          </div>
        </div>
        <div className="detail-card col-lg-6 col-md-6 col-sm-12">
          <div className="outer-circle">
            <span className="inner-number">2</span>
          </div>
          <div id="card_title">Jewelry Report</div>
          <div id="card_description">
            IGL India laboratory develops jewelry identification reports.
          </div>
        </div>
        <div className="detail-card col-lg-6 col-md-6 col-sm-12">
          <div className="outer-circle">
            <span className="inner-number">3</span>
          </div>
          <div id="card_title">Lab Grown Report</div>
          <div id="card_description">
            The Colored Stone Report is designed to identify the Lab Grown
            Diamond CVD & HPHT and variety of colored gemstones.
          </div>
        </div>
        <div className="detail-card col-lg-6 col-md-6 col-sm-12">
          <div className="outer-circle">
            <span className="inner-number">4</span>
          </div>
          <div id="card_title">Colored Stone Report</div>
          <div id="card_description">
            The Colored Stone Report is designed to identify the species and
            variety of colored gemstones.
          </div>
        </div>
      </div>
      <div className="certification-container">
        <div className="row">
          <div className="jewelery-text-container col-lg-7 col-md-7 col-sm-7 col-8">
            <div id="jewelry_title">
              We Build Solutions For Your Jewelry Certification
            </div>
            <button
              className="main_header_button"
              onClick={() => navigateToReport()}
            >
              Certify Your Jewelry Now
            </button>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-5 col-4 small_diamond_svg_container">
            <img
              className="small_diamond_svg"
              src={SMALL_DIAMONDS}
              alt="Background"
            />
          </div>
        </div>
      </div>
      <div
        className="container result_container"
        style={{ position: "relative" }}
      >
        <div className="proven_results_header_container">
          <div className="proven_results_header">
            Our Proven Process Produces Results
          </div>
        </div>
        <div className="proven_results row">
          <div className="result_card col-lg-4 col-md-4 col-sm-6 col-12 mb-lg-2 mb-md-2">
            <div className="results_title">MEETING</div>
            <p className="results_description">
              We do meet only with reputed jewelers who want to sell certified
              jewelry only across the world
            </p>
          </div>
          <div className="result_card col-lg-4 col-md-4 col-sm-6 col-12 mb-lg-2 mb-md-2">
            <div className="results_title">PLANNING</div>
            <p className="results_description">
              Our planning part is to start after a successful meeting as per
              there jewelry stock
            </p>
          </div>
          <div className="border-right-styling col-lg-4 col-md-4 col-sm-6 col-12 mb-lg-2 mb-md-2">
            <div className="results_title">EXECUTE</div>
            <p className="results_description">
              Our Certified Grader of diamond jewelry, Colored Stone, Diamond &
              Lab Grown Diamond executes our planned testing of jewelry
            </p>
          </div>

          <div className="result_card col-lg-4 col-md-4 col-sm-6 col-12 mb-lg-2 mb-md-2">
            <div className="results_title">TESTING</div>
            <p className="results_description">
              We do testing as per the international grading standard systems
            </p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-lg-2 mb-md-2">
            <div className="results_title">DELIVERY</div>
            <p className="results_description">
              We deliver a 100% international grading system
            </p>
          </div>
        </div>
      </div>
      {/* <div className="mainpage_footer">
        <div className="row">
          <div className="footer_title col-12">
          We’re Here To Help Your Business Blast Off!
          </div>
          <div className="footer_description col-12">
          Through Creative Ideas, Innovation & Sheer Determination
          </div>
          <button className="footer_button">Verify Certificate</button>
        </div>
      <img className="moon_svg" src={MOON} alt="Background" />

      </div> */}

      <Footer />
    </div>
  );
};

export default IglMainPage;
