import React, { useState } from "react";
import html2pdf from "html2pdf.js";
import download from "downloadjs";
import "./IglReportCardStyles.css";
import "./pdfStyles.css";
import ReportSummaryForm from "./ReportSummaryForm";
import ReportSummaryFormForPdf from "./ReportSummaryFormForPdf";
import { Spin } from "antd";
import IGL_LOGO from "../../assets/igl_logo.svg";
import ReportSummaryCardForm from "./ReportSummaryCardForm";
import { useSelector } from "react-redux";
import ReportCardPdf from "./ReportCardPdf";
import Footer from '../Footer/Footer'

const IglVerifyReportCard = () => {
  const [loader, setLoader] = useState(false);
  const certiData = useSelector((state) => state.certificate.certificateData);

  const exportToPDF = async () => {
    try {
      setLoader(true);
      const element = document.querySelector(
        ".export-pdf-report-summary-on-download"
      ); // Replace with your JSX element ID
      const pdfOptions = {
        margin: 10,
        filename: "report.pdf", // Customize the file name
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a2", orientation: "portrait" },
      };
      console.log("clicked", element, pdfOptions);
      const pdf = await html2pdf().from(element).set(pdfOptions).output("blob");

      download(pdf, pdfOptions.filename, "application/pdf");
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="igl-verify-report-container">
        <Spin size="large" spinning={loader} tip="Downloading Pdf...">
          {certiData && (
            <div className="report-summary-card-container fluid-container">
              <div className="report-summary-card-header-title">
                JEWELLERY REPORT
              </div>
              <div
                className="report-summary-card-download-button"
                onClick={() => exportToPDF()}
              >
                Download PDF
              </div>
            </div>
          )}
          <div className="report-form">
            {/* <ReportSummaryForm exportToPDF={exportToPDF} /> */}
            <ReportSummaryCardForm />
          </div>
        </Spin>
        <div style={{display:"none"}}>
        <span className="export-pdf-report-summary-on-download">
          <div className="report-form">
            <ReportSummaryFormForPdf />
          </div>
        </span>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default IglVerifyReportCard;
