import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import "./Header.css";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const {Item} = Menu
const HamburgerMenu = (props) => {

    const handleItemClick = () => {
        // Call the callback to close the hamburger menu
        props.onCloseHamburgerMenu()
      };

  return (
    <div className="hamburger-menu-container">
          <Button
            icon={<CloseOutlined style={{ fontSize: "62px" }} />}
            size="large"
            onClick={handleItemClick}
            className="close-hamburger-icon"
          />
    <Menu mode="inline">
        <Item  onClick={handleItemClick} key="home">
        <Link style={{ textDecoration: "none" }} to="/">
        <span className="hamburger-menubar-items">Home</span>
      </Link>
        </Item>
        <Item  onClick={handleItemClick} key="education">
      <Link style={{ textDecoration: "none" }} to="/education">
        <span className="hamburger-menubar-items">Diamond Education</span>
      </Link>
      </Item>
      {/* <Item  onClick={handleItemClick} key="report">
      <Link style={{ textDecoration: "none" }} to="/report">
        <span className="hamburger-menubar-items">Verify your report</span>
      </Link>
      </Item>
      <Item  onClick={handleItemClick} key="check-report">
      <Link style={{ textDecoration: "none" }} to="/check-report">
        <span className="hamburger-menubar-items">Check Report</span>
      </Link>
      </Item> */}
      <Item  onClick={handleItemClick} key="about">
      <Link style={{ textDecoration: "none" }} to="/about">
        <span className="hamburger-menubar-items">About Us</span>
      </Link>
      </Item>
      <Item  onClick={handleItemClick} key="contact">
      <Link style={{ textDecoration: "none" }} to="/contact">
        <span className="hamburger-menubar-items">Contact Us</span>
      </Link>
      </Item>
      <Item  onClick={handleItemClick} key="report">
      <Link style={{ textDecoration: "none" }} to="/check-report">
        <span className="hamburger-menubar-items">Verify Certificate</span>
      </Link>
      </Item>
    </Menu>
    </div>
  );
};

export default HamburgerMenu;
