/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import IglAboutUs from './../../components/AboutUs/IglAboutUs';

const AboutUsWrapper = () => {
  return (
    <>
      <IglAboutUs />
    </>
  )
}

export {AboutUsWrapper}
