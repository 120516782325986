import React from "react";
import MOON from "../../assets/moon_eclipse.svg";
import "./FooterStyles.css";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const navigateToReport = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/check-report");
  };

  return (
    <div className="mainpage_footer">
      <div className="row">
        <div className="footer_title col-12">
          We’re Here To Help Your Business Blast Off!
        </div>
        <div className="footer_description col-12">
          Through Creative Ideas, Innovation & Sheer Determination
        </div>
        <button className="footer_button" onClick={() => navigateToReport()}>
          Verify Certificate
        </button>
      </div>
      <div className="row">
        <div className="company-info-container col-lg-4 col-md-4 col-sm-6 col-12 ml-2">
          <div className="footer-company-info-title">Company</div>
          <div
            className="footer-company-info-description"
            style={{ cursor: "pointer" }}
          >
            Terms and Condition
          </div>
          <NavLink style={{ textDecoration: "none" }} to="/about">
            <div
              className="footer-company-info-description"
              style={{ cursor: "pointer" }}
            >
              About Us
            </div>
          </NavLink>
          <div
            className="footer-company-info-description"
            style={{ cursor: "pointer" }}
          >
            Privacy
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 ml-2">
          <div className="footer-company-info-title">Contact</div>
          <div
            className="footer-company-info-description"
            style={{ cursor: "pointer" }}
          >
            Igllaboratoryindia@gmail.com
          </div>
          <div
            className="footer-company-info-description"
            style={{ cursor: "pointer" }}
          >
            +91 - 9871522871
          </div>
        </div>
      </div>
      <img className="moon_svg" src={MOON} alt="Background" />
      <div className="copyright-footer">
        © 2002 - 2023 IGL is a nonprofit 501(c)(3) organization. All rights
        reserved.
      </div>
    </div>
  );
};

export default Footer;
