import React from 'react'
import axios from 'axios'

const API_URL = "https://api.igl-india.com/api/"
export const GET_ALL_KPIS_URL = `${API_URL}v1/certificate/detail`

// Get all kpis Data
export async function getCertificateReportData(certi_number) {
  try {
    let certificateData = await axios.get(`${GET_ALL_KPIS_URL}?certificate_number=${certi_number}`)

    return certificateData
  } catch (e) {
    throw e
  }
}
