import React, { useState, useEffect } from "react";
import IGL_LOGO from "../../assets/igl_logo.svg";
import "./Header.css";
import { NavLink, useLocation } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu";
import { Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";

const Header = () => {
  const [state, setState] = useState({
    visible: false,
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const showDrawer = () => {
    setState({
      ...state,
      visible: true,
    });
  };

  const closeHamburgerMenu = () => {
    setState({
      ...state,
      visible: false,
    });
  };

  return (
    <div class="container-fluid nav-main-container">
      <div className="row nav-container">
        <div className="col-lg-2 col-md-2 col-sm-3 col-6 igl_logo">
          <NavLink
            activeClassName="active-link"
            style={{ textDecoration: "none" }}
            to="/"
          >
            <div className="igl-logo-fullform">
              <img
                src={IGL_LOGO}
                alt="IGL Icon"
                style={{
                  width: "121px",
                  height: "113px",
                }}
              />
              <div className="igl-fullForm-card-container">
                <div>INTERNATIONAL</div>
                <div>GEMOLOGICAL</div>
                <div>LABORATORY</div>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="col-lg-9 col-md-9 col-sm-9 navbar_list">
          <NavLink
            activeClassName="active-link"
            style={{ textDecoration: "none" }}
            to="/education"
          >
            <span className="menubar-items">Diamond Education</span>
          </NavLink>
          {/* <NavLink activeClassName="active-link" style={{ textDecoration: "none" }} to="/report">
            <span className="menubar-items">Verify your report</span>
          </NavLink> */}
          {/* <NavLink activeClassName="active-link" style={{ textDecoration: "none" }} to="/check-report">
            <span className="menubar-items">Report Check</span>
          </NavLink> */}
          <NavLink
            activeClassName="active-link"
            style={{ textDecoration: "none" }}
            to="/about"
          >
            <span className="menubar-items">About Us</span>
          </NavLink>
          <NavLink
            activeClassName="active-link"
            style={{ textDecoration: "none" }}
            to="/contact"
          >
            <span className="menubar-items">Contact Us</span>
          </NavLink>
          <NavLink
            activeClassName="active-link"
            style={{ textDecoration: "none" }}
            to="/check-report"
          >
            <span className="menubar-items">Verify Certificate</span>
          </NavLink>
        </div>
        <div className="hamburger-menu col-sm-8 col-6">
          <Button
            icon={<MenuOutlined style={{ fontSize: "62px" }} />}
            size="large"
            onClick={showDrawer}
          />
          <Drawer
            placement="right"
            closable={true}
            visible={state.visible}
            onCloseHamburgerMenu={closeHamburgerMenu}
          >
            <HamburgerMenu onCloseHamburgerMenu={closeHamburgerMenu} />
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Header;
