/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import IglVerifyReport from './../../components/VerifyReport/IglVerifyReport';
import IglVerifyReportCard from '../../components/VerifyReport/IglVerifyReportCard';

const VerifyReportWrapper = () => {
  return (
    <>
      {/* <IglVerifyReport /> */}
      <IglVerifyReportCard />
    </>
  )
}

export {VerifyReportWrapper}
