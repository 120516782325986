import "./App.css";
import Header from "./components/Header/Header";
import MainPage from "./components/MainPage/MainPage";
import "./global.css";
import { Router } from "./routing/Routes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import {store} from "./redux/Store"

function App() {
  return (
    <Provider store={store}>
    <BrowserRouter>
    <Router />
  </BrowserRouter>
  </Provider>
    // <div className="App">
    //   <Header />
    //   <MainPage />
    // </div>
  );
}

export default App;
