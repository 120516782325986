/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import IglContactUs from './../../components/ContactUs/IglContactUs';

const ContactWrapper = () => {
  return (
    <>
      <IglContactUs />
    </>
  )
}

export {ContactWrapper}
