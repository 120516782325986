/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import CheckReport from './../../components/CheckReport/CheckReport';

const CheckReportWrapper = () => {
  return (
    <>
      <CheckReport />
    </>
  )
}

export {CheckReportWrapper}
