import React, { useState } from "react";
import Header from "../../components/Header/Header";

const AppLayoutHOC = (WrappedComponent) => {
  return (props) => (
    <div className="app">
      <Header />
        <WrappedComponent {...props} />
    </div>
  );
};

export default AppLayoutHOC;
