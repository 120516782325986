import React from "react";
import "./IglContactUsStyles.css";
import ContactUsForm from './ContactUsForm';
import ContactUsCalender from "./ContactUsCalender";
import { NavLink } from "react-router-dom";

const IglContactUs = () => {
  return (
    <div className="contact-us-container">
      <div className="background-diamond-container fluid-container">
        <div className="contact-info-main row">
          {/* <div className="background-diamond-container"> */}
          <div className="col-12 contact-title">Contact Us</div>
          <div className="col-12 contact-description">
            Get your jewelry & diamond certification by IGL India. We
            Certificate provides reliable and accurate documentation of a
            diamond’s identity and grade based on the internationally recognized
            diamond grading system.
          </div>
          <div className="col-12 contact-description">
            <div className="row">
              <div className="col-lg-6 col-md-7 col-sm-12 col-12">
                <div className="company-info-section-title">Company</div>
                <div className="company-info-section-description">
                  Address of the company will be displayed here
                </div>
                <div className="company-info-section-description">
                  Terms and Condition
                </div>
                <NavLink style={{ textDecoration: "none" }} to="/about">
                <div className="company-info-section-description">About Us</div>
                </NavLink>
                <div className="company-info-section-description">Privacy</div>
              </div>
              <div className="col-lg-6 col-md-5 col-sm-12 col-12">
                <div className="company-info-section-title">Contact</div>
                <div className="company-info-section-description">
                Igllaboratoryindia@gmail.com
                </div>
                <div className="company-info-section-description">
                  +91 - 9871522871
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="appointment-form-container container">
        <div className="apppointment-title">Set Up An Appointment.</div>
        <div className="apppointment-sub-title">For Certification Request</div>
        {/* <ContactUsForm /> */}
        <ContactUsCalender />
      </div>
    </div>
  );
};

export default IglContactUs;
