import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import "./ReportSummaryCardFormStyles.css";
import { getCertificateReportData } from "../../graphs/CheckReport/CertificateReportDataApi";
import * as certificate from "../../redux/certificate";
import { useParams } from "react-router-dom";
import { Spin } from "antd";

const ReportSummaryCardForm = ({ exportToPDF }) => {
  const certiData = useSelector((state) => state.certificate.certificateData);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { id: reportNumber } = useParams(); // Get the reportNumber from the URL

  const callCertificateData = async () => {
    try {
      setLoader(true)
      let certiData = await getCertificateReportData(reportNumber);
      if (certiData && certiData.data && certiData.data.data) {
        console.log(certiData, "data!!!");
        await dispatch(
          certificate.actions.setCertificateData(certiData.data.data)
        );
      }
    } catch (e) {
      console.log("!!!!Error", e);
      await dispatch(
        certificate.actions.setCertificateData(null)
      );
    }
    finally {
      setLoader(false)
    }
  };

  useEffect(() => {
    callCertificateData();
  }, [reportNumber])


  return (
    <>
      <Spin spinning={loader} tip={"Loading..."}>
        {certiData ? (
          <div className="report-card-form-container fluid-container">
            <div className="row">
              <div className="col-lg-8 col-sm-9 col-12">
                <div className="row card-form-row">
                  <div className="col-lg-4 col-5 card-title-field-container">
                    <span className="card-form-title">SUMMARY NO.</span>
                  </div>
                  <div className="col-lg-7 col-7">
                    <span className="card-form-value">
                      : {certiData.certificate_number
                        ? certiData.certificate_number
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className="row card-form-row">
                  <div className="col-lg-4 col-5 card-title-field-container">
                    <span className="card-form-title">DESCRIPTION</span>
                  </div>
                  <div
                    className="col-lg-7 col-7"
                    style={{ maxHeight: "80px" }}
                  >
                    <span className="card-form-value">
                      : {certiData.description ? certiData.description : "-"}
                    </span>
                  </div>
                </div>
                <div className="row card-form-row">
                  <div className="col-lg-4 col-5 card-title-field-container">
                    <span className="card-form-title">SHAPE/CUT</span>
                  </div>
                  <div
                    className="col-lg-7 col-7"
                    style={{ maxHeight: "80px" }}
                  >
                    <span className="card-form-value">
                      : {certiData.shape_cut ? certiData.shape_cut : "-"}
                    </span>
                  </div>
                </div>
                <div className="row card-form-row">
                  <div className="col-lg-4 col-5 card-title-field-container">
                    <span className="card-form-title">TOTAL EST. WT.</span>
                  </div>
                  <div
                    className="col-lg-7 col-7"
                    style={{ maxHeight: "80px" }}
                  >
                    <span className="card-form-value">
                      : {certiData.gross_weight ? certiData.gross_weight : "-"}
                    </span>
                  </div>
                </div>
                <div className="row card-form-row">
                  <div className="col-lg-4 col-5 card-title-field-container">
                    <span className="card-form-title">DIAMOND WEIGHT</span>
                  </div>
                  <div
                    className="col-lg-7 col-7"
                    style={{ maxHeight: "80px" }}
                  >
                    <span className="card-form-value">
                      : {certiData.diamond_weight ? certiData.diamond_weight : "-"}
                    </span>
                  </div>
                </div>
                <div className="row card-form-row">
                  <div className="col-lg-4 col-5 card-title-field-container">
                    <span className="card-form-title">COLOR</span>
                  </div>
                  <div
                    className="col-lg-7 col-7"
                    style={{ maxHeight: "80px" }}
                  >
                    <span className="card-form-value">
                      : {certiData.color ? certiData.color : "-"}
                    </span>
                  </div>
                </div>
                <div className="row card-form-row">
                  <div className="col-lg-4 col-5 card-title-field-container">
                    <span className="card-form-title">CLARITY</span>
                  </div>
                  <div
                    className="col-lg-7 col-7"
                    style={{ maxHeight: "80px" }}
                  >
                    <span className="card-form-value">
                      : {certiData.clarity ? certiData.clarity : "-"}
                    </span>
                  </div>
                </div>
                <div className="row card-form-row">
                  <div className="col-lg-4 col-5 card-title-field-container">
                    <span className="card-form-title">COMMENTS</span>
                  </div>
                  <div
                    className="col-lg-7 col-7"
                    style={{ maxHeight: "80px" }}
                  >
                    <span className="card-form-value">
                      : {certiData.comments ? certiData.comments : "-"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-3 col-12">
                <div
                  className="report-image-container"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <div className="report-summary-image">
                    {certiData && certiData.image_url && (
                      <img
                        src={certiData.image_url}
                        alt="diamond Image"
                        style={{
                          width: "100%",
                          height: "100%",
                          fontSize: "20px",
                          backgroundSize: "cover",
                          borderRadius: "16px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "rgba(255, 41, 25,1)",
              fontSize: "60px",
              fontWeight: "600",
              marginTop: "250px"
            }}
          >
            {!loader && <div>NO DETAILS FOUND</div>}
          </div>
        )}
      </Spin>
    </>
  );
};

export default ReportSummaryCardForm;
