/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import IglMainPage from './../../components/MainPage/IglMainPage';

const MainPageWrapper = () => {
  return (
    <>
      <IglMainPage />
    </>
  )
}

export {MainPageWrapper}
