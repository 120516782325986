import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {MainPageWrapper} from "../pages/MainPage/MainPageWrapper"
import {ContactWrapper} from "../pages/Contact/ContactWrapper"
import {AboutUsWrapper} from "../pages/AboutUs/AboutUsWrapper"
import {DiamondEducationWrapper} from "../pages/DiamondEducation/DiamondEducationWrapper"
import {VerifyReportWrapper} from "../pages/VerifyReport/VerifyReportWrapper"
import {CheckReportWrapper} from "../pages/CheckReport/CheckReportWrapper"

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPageWrapper />} />
      <Route path="/contact" element={<ContactWrapper />} />
      <Route path="/about" element={<AboutUsWrapper />} />
      <Route path="/education" element={<DiamondEducationWrapper />} />
      <Route path="/report/:id" element={<VerifyReportWrapper />} />
      <Route path="/check-report" element={<CheckReportWrapper />} />
    </Routes>
  );
};

export default PublicRoutes;
